.ja-input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.35rem 0.5rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: $ja-form-text-color;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid $ja-primary-color;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:focus{
    color: $ja-form-text-color;
    background-color: #fff;
    border-color: $ja-primary-color;
    outline: 0;
    box-shadow: none;
  }
}

.ja-border{
  border: 1px solid $ja-primary-color;
}


.ja-border-2{
  border: 2px solid $ja-primary-color;
}

.ja-border-3{
  border: 3px solid $ja-secondary-color;
}

.ja-border-right-5{
  border-right: 5px solid $ja-primary-color;
}

.ja-border-bottom-5{
  border-bottom: 5px solid $ja-primary-color;
}

label {
  display: inline-block;
  margin-bottom: 0.1rem;
}

.ja-bg-blue {
  background-color: $ja-color-blue;
}

.ja-border-blue {
  border: 2px solid $ja-color-blue;
}

.ja-border-yellow {
  border: 2px solid $ja-color-yellow;
}

.ja-border-green {
  border: 2px solid $ja-color-green;
}

.ja-border-orange {
  border: 2px solid $ja-color-orange;
}

.ja-bg-orange {
  background-color: $ja-color-orange;
}

.ja-bg-yellow {
  background-color: $ja-color-yellow;
}

.ja-bg-green{
  background-color: $ja-color-green;
}

.border-2{border: 2px solid;}
.border-3{border: 3px solid;}

//@media (max-width: 768px) {
//  .ja-border-right-5 {
//    border-right: 0;
//  }
//}

@media (max-width: 768px) {
  #my-menu {
    display: none !important;
  }
  .margin-right-auto {
    margin-right: auto;
  }
}
