.layout{
  background-color: #F9F9F9;
  //border-left: 1px solid rgba(0, 0, 0, 0.1);
  //height: calc(100vh - 80px);
  //padding-bottom: 80px;
}

.mainBlock {
  margin-bottom: 100px;
}



