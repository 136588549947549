.sharedBtn{
  border-radius: 82px;
  width: 100px;
  &:hover{
    background-color: #ecf0f1;
    color: #000000;
  }
  &:active{
    color: #000000!important;
    background-color: #ecf0f1!important;
    border-color: #6c757d!important;
    box-shadow: none;
  }
}
