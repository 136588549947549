.border{
  border: 2px solid #DFDFDF;
}

.bgColor{
  background-color: #8B9198;
}

.textColor{
  color: #8B9198;
}

.title{
  color: #8B9198;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
}

.header{
  border-radius: 20px 20px 0 0;
  padding: 10px 0;
}

.body{
  color: #EEEEEE;
  border-radius: 0 0 20px 20px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  //margin: 0 15px;
  border-top: 2px solid #EEEEEE;
  margin: 0px 10px;
}
