// Yellow
.bg-card-yellow{ background-color: $ja-card-yellow!important; }
.bg-card-light-yellow{ background-color: $ja-card-light-yellow!important; }
.bg-card-header-light-yellow{ background-color: $ja-card-header-light-yellow!important; }
.bg-card-body-light-yellow{ background-color: $ja-card-body-light-yellow!important; }
.border-card-yellow{ border-color: $ja-card-yellow!important; }
.bg-card-selected-yellow {
  //background-color: $ja-card-yellow!important;
  //opacity: 0.8;
  background-color: rgba(255, 192, 0, 0.5);
}
.bg-card-rgba-yellow{
  background-color: rgba(255, 192, 0, 0.07);
}

// Green
.bg-card-green{ background-color: $ja-card-green!important; }
.bg-card-light-green{ background-color: $ja-card-light-green!important; }
.bg-card-header-light-green{ background-color: $ja-card-header-light-green!important; }
.bg-card-body-light-green{ background-color: $ja-card-body-light-green!important; }
.border-card-green{ border-color: $ja-card-green!important; }
.bg-card-selected-green {
  //background-color: $ja-card-green!important;
  //opacity: 0.8;
  background-color: rgba(112, 173, 71, 0.5);
}

.bg-card-rgba-green {
  background-color: rgba(112, 173, 71, 0.07);
}

// Blue
.bg-card-blue{ background-color: $ja-card-blue!important; }
.bg-card-light-blue{ background-color: $ja-card-light-blue!important; }
.bg-card-header-light-blue{ background-color: $ja-card-header-light-blue!important; }
.bg-card-body-light-blue{ background-color: $ja-card-body-light-blue!important; }
.border-card-blue{ border-color: $ja-card-blue!important; }
.bg-card-selected-blue {
  //background-color: $ja-card-blue!important;
  //opacity: 0.8;
  background-color: rgba(37, 114, 178, 0.5);
}

.bg-card-rgba-blue {
  background-color: rgba(37, 114, 178, 0.07);
}

// Purple
.bg-card-purple{ background-color: $ja-card-purple!important; }
.bg-card-light-purple{ background-color: $ja-card-light-purple!important; }
.bg-card-header-light-purple{ background-color: $ja-card-header-light-purple!important; }
.bg-card-body-light-purple{ background-color: $ja-card-body-light-purple!important; }
.border-card-purple{ border-color: $ja-card-purple!important; }
.bg-card-selected-purple {
  background-color: $ja-card-purple!important;
  opacity: 0.8;
}

// primary
.bg-card-primary{ background-color: $ja-primary-color!important; }
.bg-card-light-primary{ background-color: #fff!important; }
.bg-card-header-light-primary{ background-color: #fff!important; }
.bg-card-body-light-primary{ background-color: #fff!important; }
.border-card-primary{ border-color: $ja-primary-color!important; }
.bg-card-selected-primary {
  background-color: $ja-primary-color!important;
  opacity: 0.8;
}

// Red
.bg-card-red{ background-color: $ja-card-red!important; }
.bg-card-light-red{ background-color: $ja-card-light-red!important; }
.bg-card-header-light-red{ background-color: $ja-card-header-light-red!important; }
.bg-card-body-light-red{ background-color: $ja-card-body-light-red!important; }
.border-card-red{ border-color: $ja-card-red!important; }
.bg-card-selected-red {
  background-color: $ja-card-red!important;
  opacity: 0.8;
}

// Orange
.bg-card-orange{ background-color: $ja-card-orange!important; }
.bg-card-light-orange{ background-color: $ja-card-light-orange!important; }
.bg-card-header-light-orange{ background-color: $ja-card-header-light-orange!important; }
.bg-card-body-light-orange{ background-color: $ja-card-body-light-orange!important; }
.border-card-orange{ border-color: $ja-card-orange!important; }
.bg-card-selected-orange {
  background-color: $ja-card-orange!important;
  opacity: 0.8;
}

.bg-card-gradient{
  background: radial-gradient(#fff, $ja-secondary-color);
}

.bg-card-warning{
  background-color: #E98944!important;
}


// Quickcard progress
.dot-item {
  width: 40px;
  height: 40px;
  background: #D6DBE3;
  border-radius: 50%;
  text-align: center;
  /* margin-top: 20px; */
  /* padding-top: 15px; */
  line-height: 40px;
}

.connecting-line{
  height: 2px;
  background: #D6DBE3;
  position: relative;
  width: calc(100% - 80px);
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 20px;
}

.dot-item.active:after {
  content: " ";
  position: absolute;
  border-bottom: 2px solid orange;
  top: 20px;
  left: 55px;
}

.dot-item.active{
  background-color: orange;
  color: orange;
}
