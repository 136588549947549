.sidenav {
  height: 0;
  position: fixed;
  z-index: -1;
  bottom: 0;
  right: -5px;
  background-color: #fff;
  overflow-x: hidden;
  border-left: 5px solid $ja-primary-color;
  transition: visibility .1s, opacity .3s ease-in-out;
  opacity: 0;
  visibility: hidden;
  padding: 0;
  &.opened{
    width: 300px;
    height: calc(100vh - #{$ja-header-height});
    opacity: 1;
    z-index: 1;
    visibility: visible;
    right: 0;
  }
}
