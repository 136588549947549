.modalRoot {
  position: fixed;
  top: 0;
  z-index: 100000;
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.2);
}

.modalTop {
  top: calc(50% - 100px);
}

