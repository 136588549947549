/* The container */
/* Hide the browser's default checkbox */
/* Create a custom checkbox */
/* Create the checkmark/indicator (hidden when not checked) */
/* Show the checkmark when checked */
/* Style the checkmark/indicator */
.ja-symptom-checkbox-cnt {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 27px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked {
      ~ {
        .ja-symptom-checkmark {
          &:after {
            display: block;
          }
        }
      }
    }
  }
  .ja-symptom-checkmark {
    &:after {
      content: "\d7";
      font-size: 40px;
      color: #000;
      font-weight: 400;
      position: absolute;
      top: -17px;
      left: 3px;
    }
  }
}
.ja-symptom-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  width: 30px;
  border: 2px solid;
  background-color: #fff;
  &:after {
    content: "";
    position: absolute;
    display: none;
  }
}

