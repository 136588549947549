@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,500;1,900&display=swap');
@import 'variables';
@import 'overrides';
// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap';
//@import '~mmenu-js/src/mmenu';
@import 'elements';
@import 'helper';
@import 'quickcard';
@import 'symptom-checkbox';
@import 'main';
@import 'styles';
