.fontSize{
  font-size: 16px;
}

.active{
  background-color: #FFFFFF;
  border-bottom: 1px solid #DFDFDF;
  border-radius: 18px 18px 0 0;
  //line-height: 45px;
  //padding: 0px 25px;
}

.header{
  display: flex;
  align-items: center;
  border-radius: 16px 16px 0 0;
  //overflow-x: auto;
  //overflow-y: hidden;
  height: 45px;
  .header1{
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 250px;
    height: 100%;
    font-weight: 500;
    line-height: 45px;
    padding: 0 20px;
    //color: #FFFFFF;
  }
  .header2{
    flex-grow: 3;
    flex-shrink: 0;
    display: flex;
    //color: #FFFFFF;
    & > *{
      padding: 5px 25px;
      cursor: pointer;
    }
    //font-size: 35px;
    //text-align: center;
  }
}
