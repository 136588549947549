.commentAttachment {
  position: absolute;
  top: 35px;
  left: 28px;
  display: inline-block;
  width: 85% !important;
}

.commentAttachmentText {
  word-break: break-all;
  padding: 0px 0px 0px 10px;
}

.commentDisplay{
  border-radius: 25px; 
  border: 1px solid #919FB3
}
