$ja-blue-normal: blue !default;
$ja-primary-color: #919fb3;
$ja-secondary-color: #f5f5f5;
$ja-orange-color: #ffaa3b;
$ja-form-text-color: #0c2e5d;
$ja-search-bar-color: #d6dbe3;
$enable-responsive-font-sizes: true;
$sidebar-width: 12rem;

// HEADER
$ja-header-height: 70px;
// --headline title
$ja-headline-title-font-size: 25px;
$ja-headline-title-line-height: 29px;
$ja-headline-title-font-weight: 500;
$ja-headline-title-font-style: normal;
$ja-headline-title-color: #1B3F63;
// --headline subtitle
$ja-headline-subtitle-font-size: 20px;
$ja-headline-subtitle-line-height: 23px;
$ja-headline-title-font-weight: 500;
$ja-headline-title-font-style: normal;
$ja-headline-subtitle-color: #8B9198;

// SIDEBAR
$ja-sidebar-width: 70px;

// Doctor Card colors
$ja-color-status: #2178B9;
$ja-color-blue: #5DA2E7;
$ja-color-gray: #DFDFDF;
$ja-color-green: #488E79; //8dc63f
$ja-color-yellow: #FFC000; //ffc823
$ja-color-orange: #E98944; //f7941d
$ja-color-red: #c0392b; //f7941d

$ja-speech: #5DA2E7;
$ja-physio: #488E79;
$ja-occupational: #FFC000;
$ja-consensus: #FF6633;
$ja-closed: #8B9198;

/**
 QuickCard Colors
 */
// Yellow
$ja-card-yellow: #FFC000;
$ja-card-light-yellow: #FFD966;
$ja-card-header-light-yellow: #FFD966;
$ja-card-body-light-yellow: #FFF2CC;
// Green
$ja-card-green: #70AD47;
$ja-card-light-green: #A8D08D;
$ja-card-header-light-green: #A8D08D;
$ja-card-body-light-green: #E2EFD9;
// Blue
$ja-card-blue: #2572b2;
$ja-card-light-blue: #51687a;
$ja-card-header-light-blue: #51687a;
$ja-card-body-light-blue: #c9e0f3;
// Purple
$ja-card-purple: #5f497a;
$ja-card-light-purple: #8064a2;
$ja-card-header-light-purple: #8064a2;
$ja-card-body-light-purple: #e5dfec;

// Red
$ja-card-red: #c0392b;
$ja-card-light-red: #e74c3c;
$ja-card-header-light-red: #e74c3c;
$ja-card-body-light-red: #ff7675;

// Orange
$ja-card-orange: #d35400;
$ja-card-light-orange: #e67e22;
$ja-card-header-light-orange: #e67e22;
$ja-card-body-light-orange: #ffbe76;

// Quickcard dimensions
$quickcard-min-width: 240px;
$quickcard-max-width: 340px;
$quickcard-min-height: 250px;

$user-card-max-width: 340px;
$user-card-min-width: 260px;
$user-card-min-height: 150px;
