#wrapper {
  overflow-x: hidden;
}
.ja-navbar {
  background: #FFFFFF;
  //box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  height: 80px;
}
.ja-sidebar {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
}
.right-nav {
  color: #8B9198;
  font-weight: normal;
  font-size: 13px;
}
#sidebar-wrapper {
  //min-height: 100vh;
  //border-right: 1px solid rgba(0, 0, 0, 0.1);
  margin-left: -$sidebar-width;
  -webkit-transition: margin .25s ease-out;
  -moz-transition: margin .25s ease-out;
  -o-transition: margin .25s ease-out;
  transition: margin .25s ease-out;
  color: #8B9198;
  z-index: 1;
  .sidebar-heading {
    font-size: 1.2rem;
    border: none;
  }
  .list-group {
    position: relative;
    width: $sidebar-width;
    //border-right: 1px solid rgba(0, 0, 0, 0.1);
    //height: 100vh;
    //height: 100%;
    //height: calc(100vh - 80px);
  }
}

.list-group-item + .list-group-item.active{
  border-top: 0;
}

.list-group-item-light.list-group-item-action {
  &:hover {
    color: #FFFFFF;
    background-color: #2178B9;
  }
  &:focus {
    color: #FFFFFF;
    background-color: #2178B9;
  }
}
.logout-link {
  position: fixed;
  bottom: 0px;
  width: $sidebar-width;
  //border-right: 1px solid rgba(0, 0, 0, 0.1) !important;
  font-size: 13px;
  border: none;
  //border-top: 0;
}
.list-group-flush {
  >.list-group-item {
    color: #8B9198;
    font-size: 14px;
    font-weight: 500;
  }
  >.list-group-item.active {
    background: #2178B9;
    color: white;
  }
}
#page-content-wrapper {
  min-width: 100vw;
  background: #f9f9f9;
  height: 100vh;
  overflow: auto;
  //height: calc(100vh - 80px);
}
body.sb-sidenav-toggled {
  #wrapper {
    #sidebar-wrapper {
      margin-left: 0;
    }
  }
}

// scrollbar
#page-content-wrapper::-webkit-scrollbar {
  width: 0.5em;
}

#page-content-wrapper::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}

#page-content-wrapper::-webkit-scrollbar-thumb {
  background-color:  #DFDFDF;
  outline: 1px solid darkgray;
}

@media (max-height: 651px) {
  .list-item-position{
    display: flex;
    align-items: center;
    padding: .33rem !important;
  }
  .icon-spacing{
    padding-right: 10px;
    width: 48px;
  }
}

@media (min-height: 652px) {
  .list-item-position{
    display: block;
    padding: .45rem;
  }
  .icon-spacing{
    padding-right: 0;
  }
}



@media (max-width: 767px){
  #sidebar-wrapper {
    position: absolute;
    z-index: 1000;
    height: 100vh;
  }
}

@media (min-width: 767px) {
  #sidebar-wrapper {
    margin-left: 0;
  }
  #page-content-wrapper {
    min-width: 0;
    width: 100%;
    //width: calc(100% - #{$sidebar-width});
  }
  body.sb-sidenav-toggled {
    #wrapper {
      #sidebar-wrapper {
        margin-left: -$sidebar-width;
      }
    }
  }
}

@media (max-height: 480px){
  .ja-navbar {
    height: 64px;
  }
}
