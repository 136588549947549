$form-check-input-bg: $ja-primary-color!default;
$form-check-input-width: 2em!default;
$mm_menuMaxWidth: 80px;
$mm_menuMinWidth: 70px;
$mm_menuWidth: 1;
//$mm_menuHeight: 1;
$mm_backgroundColor: #fff;
$mm_borderColor: transparent;

.mm-navbar.mm-navbar_sticky {
  display: none;
}

.mm-listitem {
  i {
    margin-right: 7px;
  }
}

.react-datepicker-wrapper{
  width: 100%;
}
